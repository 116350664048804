<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-app-bar flat scroll-target="#scrolling-techniques" color="white">
          <v-toolbar-title class="font-weight-medium">{{
            $capitalize($tc("model.reports_reports_title"))
          }}</v-toolbar-title>
        </v-app-bar>
        <v-tabs>
          <v-tab
            v-for="tab in allowedTabs"
            :key="tab.name"
            :to="{ name: tab.route }"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <router-view v-if="is_multirisks" name="multirisks"></router-view>
        <router-view v-if="is_eletric_risk" name="eletric_risk"></router-view>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { lead_types } from "@/services/lead_types";

export default {
  data() {
    const redirect = {};
    redirect[lead_types.multirisks] = "lead_visit_report";
    redirect[lead_types.riscos_eletricos] = "lead_final_reports";

    return {
      redirect,
      tabs: [
        {
          name: this.$capitalize(this.$tc("model.reports_visit_tab")),
          route: "lead_visit_report",
          lead_types: [lead_types.multirisks],
        },
        {
          name: this.$capitalize(this.$tc("model.reports_research_tab")),
          route: "lead_research_report",
          lead_types: [lead_types.multirisks],
        },
        {
          name: this.$capitalize(this.$tc("model.reports_construction_tab")),
          route: "lead_construction_report",
          lead_types: [lead_types.multirisks],
        },
        {
          name: this.$capitalize(
            this.$tc("model.photographic_report_photographic_tab")
          ),
          route: "lead_photographic_reports",
          lead_types: [lead_types.multirisks],
        },
        {
          name: this.$capitalize(this.$tc("model.final_report_final_tab")),
          route: "lead_final_reports",
          lead_types: [lead_types.riscos_eletricos, lead_types.multirisks],
        },
      ],
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    is_multirisks() {
      return this.lead.lead_type == lead_types.multirisks;
    },
    is_eletric_risk() {
      return this.lead.lead_type == lead_types.riscos_eletricos;
    },
    allowedTabs() {
      return this.tabs.filter((tab) =>
        tab.lead_types.some((type) => type === this.lead.lead_type)
      );
    },
    firstRedirect() {
      if (!this.lead?.id) {
        return null;
      }

      return this.redirect[this.lead.lead_type];
    },
  },
  watch: {
    firstRedirect: {
      handler(name) {
        if (name != null && this.$router.currentRoute?.name != name) {
          this.$router.push({ name });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
